import { Loadingbuzz } from '@admin-portal-shared-components/icons';
import { useHasPermission, useUserMetadata } from 'admin-portal-shared-services';
import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { Spinner } from 'supplier-portal-shared-components';

import { AdminForceRegistration } from './pages/Invitation/AdminForceRegistration/AdminForceRegistration';
import { InvitationPage } from './pages/Invitation/InvitationPage';
import { PrivateRoute } from './PrivateRoute/PrivateRoute';
import { LoaderContainer } from './Router.styles';

const InvitationManagement = lazy<any>(
  () => import('identity-admin-mfe/modules/invitation-management')
);

export const Router = (): JSX.Element => {
  const { isLoading } = useUserMetadata();
  const canSeeAdminCategory = useHasPermission('IdentityFE.AdminUser.Invite');
  const canSeeAdminForceCategory = useHasPermission('BeesForceAdmin.UserManagement.Write');

  if (isLoading)
    return (
      <LoaderContainer>
        <Loadingbuzz />
      </LoaderContainer>
    );

  const canSeeInviteWorkflow = canSeeAdminCategory;

  return (
    <BrowserRouter>
      <Suspense fallback={<Spinner show dataTest="transition-spinner" />}>
        <Switch>
          <PrivateRoute
            exact
            path="/invitations"
            component={InvitationPage}
            permission={canSeeInviteWorkflow}
          />

          <PrivateRoute
            exact
            path="/invitations/admin-force"
            component={AdminForceRegistration}
            permission={canSeeAdminForceCategory}
          />
          <InvitationManagement />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};
